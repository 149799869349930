@import "photoswipe.css";

@font-face {
  font-family: "Base Mono Narrow";
  font-weight: 300;
  font-display: block;
  src: url("../fonts/BaseMonoNarrowThin_6008.woff2") format("woff2"),
       url("../fonts/BaseMonoNarrowThin_6008.woff") format("woff");
}

@font-face {
  font-family: "Base Mono Wide";
  font-weight: normal;
  font-display: block;
  src: url("../fonts/BaseMonoWideReg_6012.woff2") format("woff2"),
       url("../fonts/BaseMonoWideReg_6012.woff") format("woff");
}

@font-face {
  font-family: "Base Mono Wide";
  font-weight: bold;
  font-display: block;
  src: url("../fonts/BaseMonoWideBold_6010.woff2") format("woff2"),
       url("../fonts/BaseMonoWideBold_6010.woff") format("woff");
}

@font-face {
  font-family: "Garland";
  font-weight: normal;
  font-display: block;
  src: url("../fonts/Garland.woff2") format("woff2"),
       url("../fonts/Garland.woff") format("woff");
}

@mixin non-mobile {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin phone {
  @media screen and (max-width: 767px) {
    @content;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

%clickable {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5;
}

body {
  font-family: "Base Mono Wide", monospace;
  font-size: 1.25vw;
  line-height: 1.45;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include phone {
    font-size: 5vw;
  }
}

p,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

ol,
ul {
  padding-left: 1em;
}

.image-ratio,
.icon-ratio {
  position: relative;

  img,
  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.button {
  color: inherit;
  @extend %clickable;
  font-size: 2em;
  text-decoration: underline;
  text-decoration-thickness: 1px;
}

svg {
  fill: currentColor;
  vertical-align: middle;

  use {
    pointer-events: none;
  }
}

header {
  display: flex;
  margin: 3em;

  @include phone {
    flex-direction: column;
    margin: 1em 1.5em;
  }
}

.logo {
  display: block;
  color: inherit;
  width: 25%;
  margin: 0 9em;

  @include phone {
    width: 90%;
    margin: 1em 0 3em;
  }

  &:hover {
    color: #e0d6c1;
  }
}

.filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  font-family: "Base Mono Narrow", monospace;

  @include phone {
    margin-right: 3em;
    font-size: 1.2em;
  }
}

.filter {
  display: block;
  @extend %clickable;
  margin: .1em 0;
  color: inherit;
  text-decoration: none;

  @include phone {
    margin: .175em 0;
  }

  &.is-active,
  &:hover {
    text-decoration: underline;
  }
}

.links {
  margin-left: auto;
  margin-right: 9em;
  margin-top: -1em;
  display: flex;

  @include phone {
    order: -1;
    margin-right: -.5em;
    margin-top: 0;
  }

  a {
    color: inherit;
  }

  .icon {
    width: 3em;
  }
}

$width: 3vw;
$rows: 24;
$gutter: .75vw;

.grid {
  margin: 4em auto;
  width: $width * $rows + $gutter * ($rows - 1);
  position: relative;

  @include phone {
    height: auto !important;
    margin: 3em 1em;
  }

  &:after {
    content: "";
    display: block;
    clear: left;
  }

  .show-grid & {
    background: repeating-linear-gradient(to right, hsla(0,100%,0%,.1), hsla(0,100%,0%,.1) #{$width}, transparent #{$width}, transparent #{$width + $gutter});
  }
}

.gutter-sizer {
  width: $gutter;
}

.module {
  margin-bottom: $gutter;
  float: left;

  @include phone {
    margin: .6rem 0;
    float: none;
    position: static !important;
  }

  &__text {
    padding: 1em;

    &[data-style="colorful"] {
      font-family: "Garland", cursive;
      font-size: 3.8em;
      padding: .6em .7em;
      line-height: 1;

      @for $i from 1 through 24 {
        &[data-width="#{$i}"] {
          min-height: $i * $width + ($i - 1) * $gutter;
        }
      }
    }
  }

  &__more {
    text-align: center;
    margin: 4em 0;

    .spinner {
      display: none;
      margin: 0 auto;
      width: 3em;
      height: 3em;
      border: .1em solid transparent;
      border-top-color: black;
      border-radius: 100%;
      animation: spin .8s linear infinite;
      position: relative;

      &:before {
        content: "";
        left: -.2em;
        top: -.2em;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: inherit;
        border-radius: inherit;
        border-color: hsla(0,0%,100%,.2);
      }
    }

    &.loading {
      .button {
        display: none;
      }

      .spinner {
        display: table;
      }
    }
  }

  &__date {
    font-family: "Base Mono Narrow", monospace;
    font-weight: 300;
    text-align: end;
    margin: 3em 0 2.5em;
    transition: opacity 200ms;

    @include phone {
      position: relative !important;
      left: 0;
      top: 0 !important;
      text-align: initial;
    }

    .filtered & {
      opacity: 0;
    }

    &:before {
      content: "";
      width: 2.2em;
      position: absolute;
      top: -.3em;
      right: 0;
      height: 1px;
      background: currentColor;

      @include phone {
        right: auto;
        left: 0;
      }
    }
  }
}

@keyframes spin {
  to { transform: rotate(1turn); }
}

@for $i from 1 through 24 {
  [data-width="#{$i}"] {
    width: $i * $width + ($i - 1) * $gutter;

    @include phone {
      width: 100%;
    }
  }
}

@for $i from 0 through 10 {
  [data-space-right="#{$i}"] {
    margin-right: $i * $width + ($i - 1) * $gutter;

    @include phone {
      margin-right: 0;
    }
  }
  [data-space-left="#{$i}"] {
    margin-left: $i * $width + $i * $gutter;

    @include phone {
      margin-left: 0;
    }
  }
}

figure {
  position: relative;

  a {
    display: block;
  }

  &.has-caption:hover {
    img {
      visibility: hidden;
    }

    figcaption {
      display: flex;
    }
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  figcaption {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }
}

.back-home {
  display: block;
  color: inherit;
  margin: 4em 9em 5em;
  width: 5em;

  @include phone {
    margin: 4em 2.5em 5em;
  }
}

footer {
  background: black;
  color: white;
  padding: 3em 5em 4em;

  @include phone {
    padding: 2em;
  }

  a {
    color: inherit;
  }
}
