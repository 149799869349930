@import url(photoswipe.css);
@font-face {
  font-family: "Base Mono Narrow";
  font-weight: 300;
  font-display: block;
  src: url("../fonts/BaseMonoNarrowThin_6008.woff2") format("woff2"), url("../fonts/BaseMonoNarrowThin_6008.woff") format("woff"); }

@font-face {
  font-family: "Base Mono Wide";
  font-weight: normal;
  font-display: block;
  src: url("../fonts/BaseMonoWideReg_6012.woff2") format("woff2"), url("../fonts/BaseMonoWideReg_6012.woff") format("woff"); }

@font-face {
  font-family: "Base Mono Wide";
  font-weight: bold;
  font-display: block;
  src: url("../fonts/BaseMonoWideBold_6010.woff2") format("woff2"), url("../fonts/BaseMonoWideBold_6010.woff") format("woff"); }

@font-face {
  font-family: "Garland";
  font-weight: normal;
  font-display: block;
  src: url("../fonts/Garland.woff2") format("woff2"), url("../fonts/Garland.woff") format("woff"); }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  /* 1 */ }

.button, .filter {
  cursor: pointer;
  user-select: none;
  touch-action: manipulation; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

.secretPath {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 5; }

body {
  font-family: "Base Mono Wide", monospace;
  font-size: 1.25vw;
  line-height: 1.45;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (max-width: 767px) {
    body {
      font-size: 5vw; } }

p,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1em; }
  p:last-child,
  ol:last-child,
  ul:last-child {
    margin-bottom: 0; }

ol,
ul {
  padding-left: 1em; }

.image-ratio,
.icon-ratio {
  position: relative; }
  .image-ratio img,
  .image-ratio svg,
  .icon-ratio img,
  .icon-ratio svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

.button {
  color: inherit;
  font-size: 2em;
  text-decoration: underline;
  text-decoration-thickness: 1px; }

svg {
  fill: currentColor;
  vertical-align: middle; }
  svg use {
    pointer-events: none; }

header {
  display: flex;
  margin: 3em; }
  @media screen and (max-width: 767px) {
    header {
      flex-direction: column;
      margin: 1em 1.5em; } }

.logo {
  display: block;
  color: inherit;
  width: 25%;
  margin: 0 9em; }
  @media screen and (max-width: 767px) {
    .logo {
      width: 90%;
      margin: 1em 0 3em; } }
  .logo:hover {
    color: #e0d6c1; }

.filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-end;
  font-family: "Base Mono Narrow", monospace; }
  @media screen and (max-width: 767px) {
    .filters {
      margin-right: 3em;
      font-size: 1.2em; } }

.filter {
  display: block;
  margin: .1em 0;
  color: inherit;
  text-decoration: none; }
  @media screen and (max-width: 767px) {
    .filter {
      margin: .175em 0; } }
  .filter.is-active, .filter:hover {
    text-decoration: underline; }

.links {
  margin-left: auto;
  margin-right: 9em;
  margin-top: -1em;
  display: flex; }
  @media screen and (max-width: 767px) {
    .links {
      order: -1;
      margin-right: -.5em;
      margin-top: 0; } }
  .links a {
    color: inherit; }
  .links .icon {
    width: 3em; }

.grid {
  margin: 4em auto;
  width: 89.25vw;
  position: relative; }
  @media screen and (max-width: 767px) {
    .grid {
      height: auto !important;
      margin: 3em 1em; } }
  .grid:after {
    content: "";
    display: block;
    clear: left; }
  .show-grid .grid {
    background: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 3vw, transparent 3vw, transparent 3.75vw); }

.gutter-sizer {
  width: 0.75vw; }

.module {
  margin-bottom: 0.75vw;
  float: left; }
  @media screen and (max-width: 767px) {
    .module {
      margin: .6rem 0;
      float: none;
      position: static !important; } }
  .module__text {
    padding: 1em; }
    .module__text[data-style="colorful"] {
      font-family: "Garland", cursive;
      font-size: 3.8em;
      padding: .6em .7em;
      line-height: 1; }
      .module__text[data-style="colorful"][data-width="1"] {
        min-height: 3vw; }
      .module__text[data-style="colorful"][data-width="2"] {
        min-height: 6.75vw; }
      .module__text[data-style="colorful"][data-width="3"] {
        min-height: 10.5vw; }
      .module__text[data-style="colorful"][data-width="4"] {
        min-height: 14.25vw; }
      .module__text[data-style="colorful"][data-width="5"] {
        min-height: 18vw; }
      .module__text[data-style="colorful"][data-width="6"] {
        min-height: 21.75vw; }
      .module__text[data-style="colorful"][data-width="7"] {
        min-height: 25.5vw; }
      .module__text[data-style="colorful"][data-width="8"] {
        min-height: 29.25vw; }
      .module__text[data-style="colorful"][data-width="9"] {
        min-height: 33vw; }
      .module__text[data-style="colorful"][data-width="10"] {
        min-height: 36.75vw; }
      .module__text[data-style="colorful"][data-width="11"] {
        min-height: 40.5vw; }
      .module__text[data-style="colorful"][data-width="12"] {
        min-height: 44.25vw; }
      .module__text[data-style="colorful"][data-width="13"] {
        min-height: 48vw; }
      .module__text[data-style="colorful"][data-width="14"] {
        min-height: 51.75vw; }
      .module__text[data-style="colorful"][data-width="15"] {
        min-height: 55.5vw; }
      .module__text[data-style="colorful"][data-width="16"] {
        min-height: 59.25vw; }
      .module__text[data-style="colorful"][data-width="17"] {
        min-height: 63vw; }
      .module__text[data-style="colorful"][data-width="18"] {
        min-height: 66.75vw; }
      .module__text[data-style="colorful"][data-width="19"] {
        min-height: 70.5vw; }
      .module__text[data-style="colorful"][data-width="20"] {
        min-height: 74.25vw; }
      .module__text[data-style="colorful"][data-width="21"] {
        min-height: 78vw; }
      .module__text[data-style="colorful"][data-width="22"] {
        min-height: 81.75vw; }
      .module__text[data-style="colorful"][data-width="23"] {
        min-height: 85.5vw; }
      .module__text[data-style="colorful"][data-width="24"] {
        min-height: 89.25vw; }
  .module__more {
    text-align: center;
    margin: 4em 0; }
    .module__more .spinner {
      display: none;
      margin: 0 auto;
      width: 3em;
      height: 3em;
      border: .1em solid transparent;
      border-top-color: black;
      border-radius: 100%;
      animation: spin .8s linear infinite;
      position: relative; }
      .module__more .spinner:before {
        content: "";
        left: -.2em;
        top: -.2em;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: inherit;
        border-radius: inherit;
        border-color: rgba(255, 255, 255, 0.2); }
    .module__more.loading .button {
      display: none; }
    .module__more.loading .spinner {
      display: table; }
  .module__date {
    font-family: "Base Mono Narrow", monospace;
    font-weight: 300;
    text-align: end;
    margin: 3em 0 2.5em;
    transition: opacity 200ms; }
    @media screen and (max-width: 767px) {
      .module__date {
        position: relative !important;
        left: 0;
        top: 0 !important;
        text-align: initial; } }
    .filtered .module__date {
      opacity: 0; }
    .module__date:before {
      content: "";
      width: 2.2em;
      position: absolute;
      top: -.3em;
      right: 0;
      height: 1px;
      background: currentColor; }
      @media screen and (max-width: 767px) {
        .module__date:before {
          right: auto;
          left: 0; } }

@keyframes spin {
  to {
    transform: rotate(1turn); } }

[data-width="1"] {
  width: 3vw; }
  @media screen and (max-width: 767px) {
    [data-width="1"] {
      width: 100%; } }

[data-width="2"] {
  width: 6.75vw; }
  @media screen and (max-width: 767px) {
    [data-width="2"] {
      width: 100%; } }

[data-width="3"] {
  width: 10.5vw; }
  @media screen and (max-width: 767px) {
    [data-width="3"] {
      width: 100%; } }

[data-width="4"] {
  width: 14.25vw; }
  @media screen and (max-width: 767px) {
    [data-width="4"] {
      width: 100%; } }

[data-width="5"] {
  width: 18vw; }
  @media screen and (max-width: 767px) {
    [data-width="5"] {
      width: 100%; } }

[data-width="6"] {
  width: 21.75vw; }
  @media screen and (max-width: 767px) {
    [data-width="6"] {
      width: 100%; } }

[data-width="7"] {
  width: 25.5vw; }
  @media screen and (max-width: 767px) {
    [data-width="7"] {
      width: 100%; } }

[data-width="8"] {
  width: 29.25vw; }
  @media screen and (max-width: 767px) {
    [data-width="8"] {
      width: 100%; } }

[data-width="9"] {
  width: 33vw; }
  @media screen and (max-width: 767px) {
    [data-width="9"] {
      width: 100%; } }

[data-width="10"] {
  width: 36.75vw; }
  @media screen and (max-width: 767px) {
    [data-width="10"] {
      width: 100%; } }

[data-width="11"] {
  width: 40.5vw; }
  @media screen and (max-width: 767px) {
    [data-width="11"] {
      width: 100%; } }

[data-width="12"] {
  width: 44.25vw; }
  @media screen and (max-width: 767px) {
    [data-width="12"] {
      width: 100%; } }

[data-width="13"] {
  width: 48vw; }
  @media screen and (max-width: 767px) {
    [data-width="13"] {
      width: 100%; } }

[data-width="14"] {
  width: 51.75vw; }
  @media screen and (max-width: 767px) {
    [data-width="14"] {
      width: 100%; } }

[data-width="15"] {
  width: 55.5vw; }
  @media screen and (max-width: 767px) {
    [data-width="15"] {
      width: 100%; } }

[data-width="16"] {
  width: 59.25vw; }
  @media screen and (max-width: 767px) {
    [data-width="16"] {
      width: 100%; } }

[data-width="17"] {
  width: 63vw; }
  @media screen and (max-width: 767px) {
    [data-width="17"] {
      width: 100%; } }

[data-width="18"] {
  width: 66.75vw; }
  @media screen and (max-width: 767px) {
    [data-width="18"] {
      width: 100%; } }

[data-width="19"] {
  width: 70.5vw; }
  @media screen and (max-width: 767px) {
    [data-width="19"] {
      width: 100%; } }

[data-width="20"] {
  width: 74.25vw; }
  @media screen and (max-width: 767px) {
    [data-width="20"] {
      width: 100%; } }

[data-width="21"] {
  width: 78vw; }
  @media screen and (max-width: 767px) {
    [data-width="21"] {
      width: 100%; } }

[data-width="22"] {
  width: 81.75vw; }
  @media screen and (max-width: 767px) {
    [data-width="22"] {
      width: 100%; } }

[data-width="23"] {
  width: 85.5vw; }
  @media screen and (max-width: 767px) {
    [data-width="23"] {
      width: 100%; } }

[data-width="24"] {
  width: 89.25vw; }
  @media screen and (max-width: 767px) {
    [data-width="24"] {
      width: 100%; } }

[data-space-right="0"] {
  margin-right: -0.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="0"] {
      margin-right: 0; } }

[data-space-left="0"] {
  margin-left: 0vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="0"] {
      margin-left: 0; } }

[data-space-right="1"] {
  margin-right: 3vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="1"] {
      margin-right: 0; } }

[data-space-left="1"] {
  margin-left: 3.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="1"] {
      margin-left: 0; } }

[data-space-right="2"] {
  margin-right: 6.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="2"] {
      margin-right: 0; } }

[data-space-left="2"] {
  margin-left: 7.5vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="2"] {
      margin-left: 0; } }

[data-space-right="3"] {
  margin-right: 10.5vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="3"] {
      margin-right: 0; } }

[data-space-left="3"] {
  margin-left: 11.25vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="3"] {
      margin-left: 0; } }

[data-space-right="4"] {
  margin-right: 14.25vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="4"] {
      margin-right: 0; } }

[data-space-left="4"] {
  margin-left: 15vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="4"] {
      margin-left: 0; } }

[data-space-right="5"] {
  margin-right: 18vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="5"] {
      margin-right: 0; } }

[data-space-left="5"] {
  margin-left: 18.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="5"] {
      margin-left: 0; } }

[data-space-right="6"] {
  margin-right: 21.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="6"] {
      margin-right: 0; } }

[data-space-left="6"] {
  margin-left: 22.5vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="6"] {
      margin-left: 0; } }

[data-space-right="7"] {
  margin-right: 25.5vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="7"] {
      margin-right: 0; } }

[data-space-left="7"] {
  margin-left: 26.25vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="7"] {
      margin-left: 0; } }

[data-space-right="8"] {
  margin-right: 29.25vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="8"] {
      margin-right: 0; } }

[data-space-left="8"] {
  margin-left: 30vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="8"] {
      margin-left: 0; } }

[data-space-right="9"] {
  margin-right: 33vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="9"] {
      margin-right: 0; } }

[data-space-left="9"] {
  margin-left: 33.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="9"] {
      margin-left: 0; } }

[data-space-right="10"] {
  margin-right: 36.75vw; }
  @media screen and (max-width: 767px) {
    [data-space-right="10"] {
      margin-right: 0; } }

[data-space-left="10"] {
  margin-left: 37.5vw; }
  @media screen and (max-width: 767px) {
    [data-space-left="10"] {
      margin-left: 0; } }

figure {
  position: relative; }
  figure a {
    display: block; }
  figure.has-caption:hover img {
    visibility: hidden; }
  figure.has-caption:hover figcaption {
    display: flex; }
  figure img {
    width: 100%;
    height: auto;
    vertical-align: middle; }
  figure figcaption {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1em;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none; }

.back-home {
  display: block;
  color: inherit;
  margin: 4em 9em 5em;
  width: 5em; }
  @media screen and (max-width: 767px) {
    .back-home {
      margin: 4em 2.5em 5em; } }

footer {
  background: black;
  color: white;
  padding: 3em 5em 4em; }
  @media screen and (max-width: 767px) {
    footer {
      padding: 2em; } }
  footer a {
    color: inherit; }
